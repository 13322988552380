import axios from "axios";

export default class Translation {


    async load() {
        const response = await axios.get(this.url);
        this.translations = await response.data;
    }

    constructor(url){
        this.translations = null;
        this.url = url;
    }


    translate(key, params = {}){
        let translation = key;
        if(this.translations != null) {
            translation = this.translations[key] || key;
        }

        for(var param in params) {
            translation = translation.replace('{'+param+'}', params[param]);
        }
        return translation;
    }




}
