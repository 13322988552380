export default class DatePickerHelper {
  constructor(inputElement) {
    // elements
    this._inputEl = null;
    this._rootEl = null;
    this._month1El = null;
    this._month2El = null;
    this._hiddenInputEl = null;
    this._timeContainerEl = null;
    this._timeToggleEl = null;
    this._calendar1PrevEl = null;
    this._calendar1NextEl = null;
    this._calendar2PrevEl = null;
    this._calendar2NextEl = null;
    this._dateFromInputEl = null;
    this._dateToInputEl = null;
    this._timeFromInputEl = null;
    this._timeToInputEl = null;
    this._timesIcon = null;
    this._datePickerBoxEl = null;

    this._calandar1DaysEls = [];
    this._calandar2DaysEls = [];
    this._typeListButtonEls = [];
    this._rangeButtonEls = [];

    // settings
    this._typeLabel = null;
    this._label = "Date";

    this._selectedType = null;
    this._defaultTypeOption = null;

    this._defaultFrom = new Date(this.constructor._now);
    this._defaultTo = new Date(this.constructor._now);

    this._timeEnabled = false;
    this._timeToggled = false;
    this._timeToggledOriginally = false;

    this._typeOptions = [];
    this._rangeButtons = [];

    this._from = new Date(this.constructor._now);
    this._to = new Date(this.constructor._now);

    this._calendarDate1 = new Date(this.constructor._now);
    this._calendarDate2 = new Date(this.constructor._now);

    this._init(inputElement);
  }

  _init(inputElement) {
    inputElement = this.constructor._getElement(inputElement);

    if (!(inputElement instanceof HTMLInputElement)) {
      throw "Argument is not input element";
    }

    if (this.constructor._inputToPickerMapping.has(inputElement)) {
      throw "Date picker already constructed";
    }

    this.constructor._inputToPickerMapping.set(inputElement, this);

    this._inputEl = inputElement;
    this._extractSettings();
    this._constructCalendar();
    this._refreshCalendars();
    this._updateDateTimeInputs();
    this._addEventListeners();
    this._updatePickerInput();
  }

  _addResizeListener() {
    const listener = () => {
      if (!this._inputEl.closest("body")) {
        window.removeEventListener("resize", listener);
        return;
      }

      if (this._rootEl.style.display !== "none") {
        this._resizePicker();
      }
    };

    window.addEventListener("resize", listener);
  }

  _extractSettings() {
    if (this._inputEl.previousElementSibling instanceof HTMLLabelElement) {
      this._label = this._inputEl.previousElementSibling.innerText;
    }

    if (
      this._inputEl.dataset.typeOptions &&
      this._inputEl.dataset.typeOptions !== "null"
    ) {
      this._typeOptions = JSON.parse(this._inputEl.dataset.typeOptions);
    }

    if (
      this._inputEl.dataset.rangeButtons &&
      this._inputEl.dataset.rangeButtons !== "null"
    ) {
      this._rangeButtons = JSON.parse(this._inputEl.dataset.rangeButtons);
    }

    this._timeEnabled = this._inputEl.dataset.timeEnabled === "true";
    this._timeToggledOriginally =
      this._timeEnabled && this._inputEl.value.includes(":");
    this._typeLabel = this._inputEl.dataset.typeLabel;
    this._selectedType = this._inputEl.dataset.selectedType;
    this._defaultTypeOption = this._inputEl.dataset.defaultTypeOption;

    [this._from, this._to] = this._inputEl.value
      .split(/\s*-\s*/gm)
      .map((item) => this._parseDate(item));

    if (this._from < this.constructor._maxInvalidDate) {
      this._from = new Date(this.constructor._maxInvalidDate);
    }

    if (this._to < this.constructor._minValidDate) {
      this._to = new Date(this.constructor._minValidDate);
    }

    this._calendarDate1 = new Date(this._from);
    this._calendarDate2 = new Date(this._to);

    if (this._calendarDate1 < this.constructor._minValidDate) {
      this._calendarDate1 = new Date(this.constructor._minValidDate);
    }

    this._defaultFrom = this._parseDate(this._inputEl.dataset.defaultFrom);
    this._defaultTo = this._parseDate(this._inputEl.dataset.defaultTo);

    if (!this._timeToggledOriginally && this._timeEnabled) {
      this._from.setHours(0, 0);
      this._to.setHours(0, 0);
    }
  }

  _parseDate(dateString) {
    try {
      if (!dateString || !dateString.trim()) {
        return new Date(this.constructor._maxInvalidDate);
      }
      dateString = dateString.trim();
      dateString = dateString
        .split(" ")
        .map((item) => item.split(".").reverse().join("-"))
        .join(" ");

      const date = new Date(dateString);

      if (!this._timeEnabled) {
        date.setHours(0, 0);
      }

      date.setSeconds(0, 0);
      return date;
    } catch (e) {
      return new Date(this.constructor._now);
    }
  }

  _addOpenCloseListeners() {
    let preventClick = false;

    const listener = (ev) => {
      if (ev.type === "focus" && ev.target === this._inputEl) {
        preventClick = true;
        setTimeout(() => {
          preventClick = false;
        }, 300);
      }

      if (ev.type === "click" && preventClick) {
        return;
      }

      if (!this._inputEl.closest("body")) {
        window.removeEventListener("focus", listener, true);
        window.removeEventListener("click", listener, true);
        return;
      }

      if (
        !(ev.target instanceof HTMLElement) ||
        this._rootEl.contains(ev.target)
      ) {
        return;
      }

      if (
        ev.target === this._inputEl &&
        this._rootEl.style.display === "none"
      ) {
        this._openPicker();
        return;
      }

      this._closePicker();
    };

    window.addEventListener("focus", listener, true);
    window.addEventListener("click", listener, true);
  }

  _addEventListeners() {
    this._calendar1PrevEl.onclick = () => {
      this._calendarDate1.setMonth(this._calendarDate1.getMonth() - 1);
      this._refreshCalendars();
    };

    this._calendar2PrevEl.onclick = () => {
      this._calendarDate2.setMonth(this._calendarDate2.getMonth() - 1);
      this._refreshCalendars();
    };

    this._calendar1NextEl.onclick = () => {
      this._calendarDate1.setMonth(this._calendarDate1.getMonth() + 1);
      this._refreshCalendars();
    };

    this._calendar2NextEl.onclick = () => {
      this._calendarDate2.setMonth(this._calendarDate2.getMonth() + 1);
      this._refreshCalendars();
    };

    this._calandar1DaysEls.forEach((el) => {
      el.onclick = () => {
        if (!el.classList.contains("disabled")) {
          const date = new Date(el.dataset.date);
          this._from.setFullYear(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
          );
          this._calendarDate1.setFullYear(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
          );
          this._refreshCalendars();
          this._updateDateTimeInputs();
        }
      };
    });

    this._calandar2DaysEls.forEach((el) => {
      el.onclick = () => {
        if (!el.classList.contains("disabled")) {
          const date = new Date(el.dataset.date);
          this._to.setFullYear(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
          );
          this._calendarDate2.setFullYear(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
          );
          this._refreshCalendars();
          this._updateDateTimeInputs();
          this._closePicker();
        }
      };
    });

    this._hiddenInputEl.onfocus = () => {
      this._closePicker(true);
    };

    this._timeToggleEl.onclick = () => {
      this._toggleTime();
    };

    this._initButtonCycling(this._typeListButtonEls);
    this._initButtonCycling(this._rangeButtonEls);

    this._typeListButtonEls.forEach((el) => {
      el.onclick = () => {
        this._selectedType = el.dataset.value;
        this._typeListButtonEls.forEach((button) =>
          button.classList.remove("selected")
        );
        el.classList.add("selected");
      };
    });

    this._rangeButtonEls.forEach((el) => {
      el.onclick = () => {
        this._selectDateRange(el.dataset.type);
      };
    });

    this._timeFromInputEl.onchange =
      this._timeToInputEl.onchange =
      this._dateFromInputEl.onchange =
      this._dateToInputEl.onchange =
        () => {
          this._updateCalendarDateTime();
        };

    this._timeFromInputEl.onblur =
      this._timeToInputEl.onblur =
      this._dateFromInputEl.onblur =
      this._dateToInputEl.onblur =
        () => {
          this._updateDateTimeInputs();
        };

    this._initDateTimeKeyboardSupport();
    this._addOpenCloseListeners();
    this._addResizeListener();
  }

  _toggleTime() {
    this._timeContainerEl.classList.toggle("collapsed");
    this._timesIcon.classList.toggle("hidden");
    this._timeToggled = !this._timeToggled;
    console.log(this._timeToggled);
  }

  _initButtonCycling(buttonArray) {
    buttonArray.forEach((el, i) => {
      el.onkeydown = (ev) => {
        const prevKeys = ["ArrowLeft", "ArrowUp"];
        const nextKeys = ["ArrowRight", "ArrowDown"];

        const idx = i + buttonArray.length;

        if (prevKeys.includes(ev.code)) {
          buttonArray[(idx - 1) % buttonArray.length].focus();
          ev.preventDefault();
        } else if (nextKeys.includes(ev.code)) {
          buttonArray[(idx + 1) % buttonArray.length].focus();
          ev.preventDefault();
        }
      };
    });
  }

  _selectDateRange(type) {
    this._to = new Date();
    this._from = new Date();

    switch (type) {
      case "day":
        this._from.setHours(0, 0, 0, 0);
        break;
      case "week":
        this._from.setDate(
          this._from.getDate() - ((this._from.getDay() - 1) % 7)
        );
        this._from.setHours(0, 0, 0, 0);
        break;
      case "month":
        this._from.setFullYear(
          this._from.getFullYear(),
          this._from.getMonth(),
          1
        );
        this._from.setHours(0, 0, 0, 0);
        break;
      case "year":
        this._from.setFullYear(this._from.getFullYear(), 0, 1);
        this._from.setHours(0, 0, 0, 0);
        break;
      case "none":
        this._from = new Date(this.constructor._maxInvalidDate);
        break;
    }

    if (type === "none") {
      this._calendarDate1 = new Date(this.constructor._minValidDate);
    } else {
      this._calendarDate1 = new Date(this._from);
    }

    this._calendarDate2 = new Date(this._to);

    this._refreshCalendars();
    this._updateDateTimeInputs();
    this._closePicker();
  }

  _updatePickerInput() {
    if (
      this._timeToggled === this._timeToggledOriginally &&
      (!this._typeOptions.length ||
        this._selectedType == this._defaultTypeOption) &&
      this._from.getFullYear() === this._defaultFrom.getFullYear() &&
      this._from.getMonth() === this._defaultFrom.getMonth() &&
      this._from.getDate() === this._defaultFrom.getDate() &&
      (!this._timeToggled ||
        (this._from.getHours() === this._defaultFrom.getHours() &&
          this._from.getMinutes() === this._defaultFrom.getMinutes())) &&
      this._to.getFullYear() === this._defaultTo.getFullYear() &&
      this._to.getMonth() === this._defaultTo.getMonth() &&
      this._to.getDate() === this._defaultTo.getDate() &&
      (!this._timeToggled ||
        (this._to.getHours() === this._defaultTo.getHours() &&
          this._to.getMinutes() === this._defaultTo.getMinutes()))
    ) {
      this._datePickerBoxEl.classList.remove("value-changed");
    } else {
      this._datePickerBoxEl.classList.add("value-changed");
    }

    this._inputEl.dataset.selectedType = this._selectedType;

    let displayText = "";
    let inputValue = "";

    const selectedOption = this._typeOptions.find(
      (option) => option.value == this._selectedType
    );

    if (selectedOption) {
      displayText += selectedOption.label + "  ";
    }

    if (this._from.getFullYear() < 2013) {
      displayText += "None - ";
      inputValue += " - ";
    } else {
      displayText += this._getFormattedDate(this._from) + " - ";
      inputValue += this._getFormattedDate(this._from);

      if (this._timeToggled) {
        inputValue += " " + this._getFormattedTime(this._from);
      }

      inputValue += " - ";
    }

    displayText += this._getFormattedDate(this._to);
    inputValue += this._getFormattedDate(this._to);

    if (this._timeToggled) {
      inputValue += " " + this._getFormattedTime(this._to);
    }

    this._inputEl.value = inputValue;
    this._datePickerBoxEl.dataset.displayText = displayText;
  }

  _refreshCalendars() {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const fromDate = new Date(this._from);
    const toDate = new Date(this._to);

    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(0, 0, 0, 0);

    this._month1El.innerText =
      months[this._calendarDate1.getMonth()] +
      " " +
      this._calendarDate1.getFullYear();
    this._month2El.innerText =
      months[this._calendarDate2.getMonth()] +
      " " +
      this._calendarDate2.getFullYear();

    if (
      this._calendarDate1.getMonth() === toDate.getMonth() &&
      this._calendarDate1.getFullYear() === toDate.getFullYear()
    ) {
      this._calendar1NextEl.style.visibility = "hidden";
    } else {
      this._calendar1NextEl.style.visibility = "";
    }

    if (
      this._calendarDate2.getMonth() === fromDate.getMonth() &&
      this._calendarDate2.getFullYear() === fromDate.getFullYear()
    ) {
      this._calendar2PrevEl.style.visibility = "hidden";
    } else {
      this._calendar2PrevEl.style.visibility = "";
    }

    if (
      this._calendarDate1.getFullYear() <= 2013 &&
      this._calendarDate1.getMonth() === 0
    ) {
      this._calendar1PrevEl.style.visibility = "hidden";
    } else {
      this._calendar1PrevEl.style.visibility = "";
    }

    [this._calendarDate1, this._calendarDate2].forEach((calendarDate) => {
      const date = new Date(calendarDate);
      date.setDate(1);
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() - date.getDay());

      const daysEls =
        calendarDate === this._calendarDate1
          ? this._calandar1DaysEls
          : this._calandar2DaysEls;

      daysEls.forEach((el) => {
        el.innerText = date.getDate();
        el.dataset.date = date.toISOString();
        el.classList.remove(
          "available",
          "active",
          "start-date",
          "end-date",
          "off",
          "disabled",
          "in-range"
        );

        if (
          date.getFullYear() >= 2013 &&
          ((daysEls === this._calandar1DaysEls && date <= toDate) ||
            (daysEls === this._calandar2DaysEls && date >= fromDate))
        ) {
          if (
            (daysEls === this._calandar1DaysEls &&
              date.getMonth() === this._calendarDate1.getMonth()) ||
            (daysEls === this._calandar2DaysEls &&
              date.getMonth() === this._calendarDate2.getMonth())
          ) {
            el.classList.add("available");
          } else {
            el.classList.add("off");
          }

          if (date > fromDate && date < toDate) {
            el.classList.add("in-range");
          }

          if (date.valueOf() === fromDate.valueOf()) {
            if (daysEls === this._calandar1DaysEls) {
              el.classList.add("start-date");

              if (fromDate.valueOf() === toDate.valueOf()) {
                el.classList.add("end-date");
              }
            } else {
              el.classList.add("in-range");
            }
          }

          if (date.valueOf() === toDate.valueOf()) {
            if (daysEls === this._calandar2DaysEls) {
              el.classList.add("end-date");

              if (fromDate.valueOf() === toDate.valueOf()) {
                el.classList.add("start-date");
              }
            } else {
              el.classList.add("in-range");
            }
          }
        } else {
          el.classList.add("off", "disabled");
        }

        date.setDate(date.getDate() + 1);
      });
    });
  }

  _htmlEscape(text) {
    const span = document.createElement("span");
    span.innerText = text;
    return span.innerHTML;
  }

  _constructCalendar() {
    this._rootEl = document.createElement("div");
    this._rootEl.className =
      "daterangepicker dropdown-menu show-calendar opensright";
    this._rootEl.style.display = "none";

    this._rootEl.innerHTML = `
      <div class="daterangepicker-type-block">
          <h4 class="daterangepicker-type-label">${this._htmlEscape(
            this._typeLabel
          )}</h4>
          <div class="daterangepicker-type-options-list">
            ${this._typeOptions
              .map(
                (opt) =>
                  `<button data-value="${this._htmlEscape(opt.value)}" class="${
                    opt.value == this._selectedType ? "selected" : ""
                  }">${this._htmlEscape(opt.label)}</button>`
              )
              .join("")}
          </div>
          <div class="times collapsed">
          <label>Time (From-to)</label>
          <button class="times-toggle"></button>
          <span class="arrow"></span>
          </div>
      </div>
      <div class="daterangepicker-title-block">
          <h4>${this._htmlEscape(this._label)}</h4>
      </div>
      <div class="calendar right">
          <div class="calendar-date">
              <table class="table-condensed">
                  <thead>
                      <tr>
                          <th class="prev available"><i class="fa fa-arrow-left icon-arrow-left glyphicon glyphicon-arrow-left"></i></th>
                          <th colspan="5" class="month month2"></th>
                          <th class="next available"><i class="fa fa-arrow-right icon-arrow-right glyphicon glyphicon-arrow-right"></i></th>
                      </tr>
                      <tr>
                          <th>Su</th>
                          <th>Mo</th>
                          <th>Tu</th>
                          <th>We</th>
                          <th>Th</th>
                          <th>Fr</th>
                          <th>Sa</th>
                      </tr>
                  </thead>
                  <tbody class="days2">
                      <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                      <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                      <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                      <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                      <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                      <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="calendar-time">
              <select class="hourselect"></select> : <select class="minuteselect"></select> 
          </div>
      </div>
      <div class="calendar left">
          <div class="calendar-date">
              <table class="table-condensed">
                  <thead>
                      <tr>
                          <th class="prev available"><i class="fa fa-arrow-left icon-arrow-left glyphicon glyphicon-arrow-left"></i></th>
                          <th colspan="5" class="month month1"></th>
                          <th class="next available"><i class="fa fa-arrow-right icon-arrow-right glyphicon glyphicon-arrow-right"></i></th>
                      </tr>
                      <tr>
                          <th>Su</th>
                          <th>Mo</th>
                          <th>Tu</th>
                          <th>We</th>
                          <th>Th</th>
                          <th>Fr</th>
                          <th>Sa</th>
                      </tr>
                  </thead>
                  <tbody class="days1">
                      <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                      <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                      <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                      <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                      <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                      <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="calendar-time">
              <select class="hourselect"></select> : <select class="minuteselect"></select> 
          </div>
      </div>
      <div class="ranges" style="display: none;">
      </div>
      <div class="date-time-block">
          <div class="dates">
              <label>Date (From-to)</label>
              <input class="date-from">
              <span class="arrow"></span>
              <input class="date-to"></div>
          <div class="times f_times collapsed">
              <label>Time (From-to)</label>
              <input class="time-from">
              <span class="arrow"></span>
              <input class="time-to">
          </div>
      </div>
      <div class="daterangepicker-range-buttons">
          ${["day", "week", "month", "year", "none"]
            .filter((type) => this._rangeButtons.includes(type))
            .map(
              (type) =>
                `<button data-type="${type}">${
                  type !== "none" ? `Current ${type}` : "None"
                }</button>`
            )
            .join("")}
      </div>
      <input class="f_hidden_input" type="button" style="opacity: 0; pointer-events: none; position: absolute">
      `;

    this._timeToggleEl = this._rootEl.querySelector(".times-toggle");
    this._timeContainerEl = this._rootEl.querySelector(".f_times");
    this._month1El = this._rootEl.querySelector(".month1");
    this._month2El = this._rootEl.querySelector(".month2");
    this._hiddenInputEl = this._rootEl.querySelector(".f_hidden_input");
    this._calandar1DaysEls = [...this._rootEl.querySelectorAll(".days1 td")];
    this._calandar2DaysEls = [...this._rootEl.querySelectorAll(".days2 td")];
    this._typeListButtonEls = [
      ...this._rootEl.querySelectorAll(
        ".daterangepicker-type-block button[data-value]"
      ),
    ];
    this._rangeButtonEls = [
      ...this._rootEl.querySelectorAll(".daterangepicker-range-buttons button"),
    ];

    this._calendar1PrevEl = this._rootEl.querySelector(
      ".calendar.left .prev.available"
    );
    this._calendar1NextEl = this._rootEl.querySelector(
      ".calendar.left .next.available"
    );
    this._calendar2PrevEl = this._rootEl.querySelector(
      ".calendar.right .prev.available"
    );
    this._calendar2NextEl = this._rootEl.querySelector(
      ".calendar.right .next.available"
    );

    this._dateFromInputEl = this._rootEl.querySelector(".date-from");
    this._dateToInputEl = this._rootEl.querySelector(".date-to");
    this._timeFromInputEl = this._rootEl.querySelector(".time-from");
    this._timeToInputEl = this._rootEl.querySelector(".time-to");
    this._timesIcon = this._inputEl.parentElement.querySelector("button");
    this._datePickerBoxEl = this._inputEl.closest(".datepicker-box");

    if (this._timeToggledOriginally) {
      this._toggleTime();
    }

    const typeListEl = this._rootEl.querySelector(
      ".daterangepicker-type-block"
    );

    if (!this._typeLabel || !this._typeOptions.length) {
      this._selectedType = null;
      typeListEl.remove();
    }

    if (!this._timeEnabled) {
      this._rootEl
        .querySelectorAll(".times")
        .forEach((e) => (e.style.display = "none"));
    } else if (!this._typeLabel || !this._typeOptions.length) {
      this._timeContainerEl.firstElementChild.after(this._timeToggleEl);
    }

    document.body.append(this._rootEl);
  }

  static _getElement(inputElement) {
    if (!(inputElement instanceof HTMLElement)) {
      inputElement = $(inputElement)[0];
    }

    return inputElement;
  }

  static initDatePickers() {
    document.querySelectorAll(".range-datepicker").forEach((element) => {
      try {
        new this(element);
      } catch (e) {
        console.error(e);
      }
    });
  }

  _resizePicker() {
    const rect = this._inputEl.getBoundingClientRect();
    this._rootEl.style.top = `${rect.bottom}px`;

    if (window.innerWidth <= 800) {
      this._rootEl.style.left = "";
      return;
    }

    let offset = rect.left;

    if (offset + 810 > window.innerWidth) {
      offset = window.innerWidth - 820;
    }

    this._rootEl.style.left = `${offset}px`;
  }

  _openPicker() {
    this._resizePicker();
    this._rootEl.style.display = "block";
    this._inputEl.classList.add("active");

    setTimeout(() => {
      if (this._rootEl.style.display !== "none") {
        if (this._typeOptions.length) {
          this._typeListButtonEls[0].focus();
        } else if (this._timeEnabled) {
          this._timeToggleEl.focus();
        } else {
          this._timeFromInputEl.focus();
        }
      }
    }, 50);
  }

  _closePicker(focusOnNextElement = false) {
    this._rootEl.style.display = "none";
    this._inputEl.classList.remove("active");

    this._updatePickerInput();

    if (!focusOnNextElement) {
      return;
    }

    const walker = document.createTreeWalker(
      document.body,
      NodeFilter.SHOW_ELEMENT,
      {
        acceptNode: (node) =>
          (node instanceof HTMLInputElement && node.type !== "hidden") ||
          (node instanceof HTMLButtonElement &&
            node.parentElement.classList.contains("bootstrap-select"))
            ? NodeFilter.FILTER_ACCEPT
            : NodeFilter.FILTER_SKIP,
      }
    );

    walker.currentNode = this._inputEl;
    const nextFilter = walker.nextNode();

    if (nextFilter) {
      setTimeout(() => {
        nextFilter.focus();
      }, 50);
    }
  }

  _initDateTimeKeyboardSupport() {
    function correctDate(date) {
      if (isNaN(date.getTime())) {
        date.setFullYear(2013, 0, 1);
        date.setHours(0, 0, 0, 0);
      }

      if (date.getFullYear() < 2013) {
        date.setFullYear(2013);
      }
    }

    this._dateFromInputEl.onkeydown = this._dateToInputEl.onkeydown = (e) => {
      if (e.code === "ArrowUp" || e.code === "ArrowDown") {
        const dateText =
          e.target.value.split(".").reverse().join("-") + " 00:00";
        let caretPositionStart = e.target.selectionStart;
        let caretPositionEnd;
        const dateTime = dateText.includes("-")
          ? new Date(dateText)
          : new Date("2013-01-02 00:00");
        correctDate(dateTime);

        const increment = e.code === "ArrowUp" ? 1 : -1;

        if (caretPositionStart < 3) {
          caretPositionStart = 0;
          caretPositionEnd = 2;
          dateTime.setDate(dateTime.getDate() + increment);
        } else if (caretPositionStart < 6) {
          caretPositionStart = 3;
          caretPositionEnd = 5;
          dateTime.setMonth(dateTime.getMonth() + increment);
        } else {
          caretPositionStart = 6;
          caretPositionEnd = 10;
          dateTime.setFullYear(dateTime.getFullYear() + increment);
        }

        if (dateTime.getFullYear() > 2012) {
          e.target.value =
            this._addTrailingZeroes(dateTime.getDate(), 2) +
            "." +
            this._addTrailingZeroes(dateTime.getMonth() + 1, 2) +
            "." +
            this._addTrailingZeroes(dateTime.getFullYear(), 4);
          e.target.setSelectionRange(caretPositionStart, caretPositionEnd);
          this._emitChangeWithDelay(e.target);
        }

        e.preventDefault();
      } else if (e.code === "ArrowLeft" || e.code === "ArrowRight") {
        let caretPositionStart =
          e.target.selectionStart + (e.code === "ArrowLeft" ? -3 : 3);
        let caretPositionEnd;

        if (caretPositionStart < 3) {
          caretPositionStart = 0;
          caretPositionEnd = 2;
        } else if (caretPositionStart < 6) {
          caretPositionStart = 3;
          caretPositionEnd = 5;
        } else {
          caretPositionStart = 6;
          caretPositionEnd = 10;
        }

        e.target.setSelectionRange(caretPositionStart, caretPositionEnd);
        e.preventDefault();
      }
    };

    this._timeFromInputEl.onkeydown = this._timeToInputEl.onkeydown = (e) => {
      if (e.code === "ArrowUp" || e.code === "ArrowDown") {
        const dateText = e.target.value.includes(":")
          ? "2013-01-01 " + e.target.value
          : "2013-01-01";
        let caretPositionStart = e.target.selectionStart;
        let caretPositionEnd;
        const dateTime = new Date(dateText);
        correctDate(dateTime);
        const increment = e.code === "ArrowUp" ? 1 : -1;

        if (caretPositionStart < 3) {
          caretPositionStart = 0;
          caretPositionEnd = 2;
          dateTime.setHours(dateTime.getHours() + increment);
        } else {
          caretPositionStart = 3;
          caretPositionEnd = 5;
          dateTime.setMinutes(dateTime.getMinutes() + increment);
        }

        if (dateTime.getFullYear() > 2012) {
          e.target.value =
            this._addTrailingZeroes(dateTime.getHours(), 2) +
            ":" +
            this._addTrailingZeroes(dateTime.getMinutes(), 2);
          e.target.setSelectionRange(caretPositionStart, caretPositionEnd);
          this._emitChangeWithDelay(e.target);
        }

        e.preventDefault();
      } else if (e.code === "ArrowLeft" || e.code === "ArrowRight") {
        let caretPositionStart =
          e.target.selectionStart + (e.code === "ArrowLeft" ? -3 : 3);
        let caretPositionEnd;

        if (caretPositionStart < 3) {
          caretPositionStart = 0;
          caretPositionEnd = 2;
        } else {
          caretPositionStart = 3;
          caretPositionEnd = 5;
        }

        e.target.setSelectionRange(caretPositionStart, caretPositionEnd);
        e.preventDefault();
      }
    };
  }

  _getFormattedDate(dateObj) {
    return `${dateObj.getDate().toString().padStart(2, "0")}.${(
      dateObj.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}.${dateObj.getFullYear()}`;
  }

  _getFormattedTime(dateObj) {
    return `${dateObj.getHours().toString().padStart(2, "0")}:${dateObj
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  }

  _updateDateTimeInputs() {
    if (this._from.getFullYear() < 2013) {
      this._dateFromInputEl.value = this._timeFromInputEl.value = "";
    } else {
      this._dateFromInputEl.value = this._getFormattedDate(this._from);
      this._timeFromInputEl.value = this._getFormattedTime(this._from);
    }

    this._dateToInputEl.value = this._getFormattedDate(this._to);
    this._timeToInputEl.value = this._getFormattedTime(this._to);
  }

  _updateCalendarDateTime() {
    let dateFrom = this._dateFromInputEl.value;
    let dateTo = this._dateToInputEl.value;

    let timeFrom = this._timeEnabled ? this._timeFromInputEl.value : "";
    let timeTo = this._timeEnabled ? this._timeToInputEl.value : "";

    if (dateFrom.length < 2) {
      dateFrom = "31.12.2012";
    }

    try {
      let from = new Date(
        (dateFrom.split(".").reverse().join("-") + " " + timeFrom)
          .replace(/\//g, "-")
          .trim()
      );
      let to = new Date(
        (dateTo.split(".").reverse().join("-") + " " + timeTo)
          .replace(/\//g, "-")
          .trim()
      );

      if (!isNaN(to.getTime()) && !isNaN(from.getTime())) {
        if (to < from) {
          [to, from] = [from, to];
        }

        if (from < this.constructor._maxInvalidDate) {
          from = new Date(this.constructor._maxInvalidDate);
        }

        if (to < this.constructor._minValidDate) {
          to = new Date(this.constructor._minValidDate);
        }

        this._from.setFullYear(
          from.getFullYear(),
          from.getMonth(),
          from.getDate()
        );
        this._from.setHours(from.getHours(), from.getMinutes());
        this._calendarDate1.setFullYear(
          from.getFullYear(),
          from.getMonth(),
          from.getDate()
        );
        this._to.setFullYear(to.getFullYear(), to.getMonth(), to.getDate());
        this._to.setHours(to.getHours(), to.getMinutes());
        this._calendarDate2.setFullYear(
          to.getFullYear(),
          to.getMonth(),
          to.getDate()
        );

        if (this._calendarDate1 < this.constructor._minValidDate) {
          this._calendarDate1 = new Date(this.constructor._minValidDate);
        }

        this._refreshCalendars();
      }
    } catch (e) {
      console.error(e);
    }
  }

  _addTrailingZeroes(number, places) {
    const zero = places - number.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + number;
  }

  _emitChangeWithDelay(input) {
    if (!input.dataset.willEmitChange) {
      input.dataset.willEmitChange = "true";

      setTimeout(() => {
        input.dispatchEvent(new Event("change"));
        delete input.dataset.willEmitChange;
      }, 100);
    }
  }

  static getDateFrom(inputElement) {
    inputElement = this._getElement(inputElement);
    return inputElement.value.split(/\s*-\s*/gm)[0];
  }

  static getDateTo(inputElement) {
    inputElement = this._getElement(inputElement);
    return inputElement.value.split(/\s*-\s*/gm)[1];
  }

  static getTypeOption(inputElement) {
    inputElement = this._getElement(inputElement);

    if (inputElement.dataset.selectedType) {
      return +inputElement.dataset.selectedType;
    }

    return null;
  }

  static initDatepickerChangeDateToastrErrors() {
    $(".datepicker-from")
      .on("changeDate", function (e) {
        if (e.date.getDate() != 1) {
          toastr.error(
            window.i18n.translate("datepicker.error.billing-start-first-day"),
            window.i18n.translate('datepicker.error.billing'),
            { timeOut: "6000" }
          );
        }
      });

    $(".datepicker-to")
      .on("changeDate", (e) => {
        if (!this._isLastDay(e.date)) {
          toastr.error(
            window.i18n.translate('datapicker.error.billing-end-last-day'),
            window.i18n.translate('datepicker.error.billing'),
            { timeOut: "6000" }
          );
        }
      });
  }

  static _isLastDay(dt) {
    let test = new Date(dt.getTime());
    test.setDate(test.getDate() + 1);
    return test.getDate() === 1;
  }
}

DatePickerHelper._inputToPickerMapping = new WeakMap();
DatePickerHelper._now = new Date();
DatePickerHelper._minValidDate = new Date("2013-01-01 00:00:00");
DatePickerHelper._maxInvalidDate = new Date("2012-12-31 23:59:59");
