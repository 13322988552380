import ToastManager from "./ToastManager";
import PusherHelper from "./PusherHelper";
import DatePickerHelper from "./DatePickerHelper";
import MultiSelectHelper from "./MultiSelectHelper";
import ThemeSwitcher from "./ThemeSwitcher";
import Navigation from "./Navigation";
import AjaxManager from "./AjaxManager";
import AjaxLoader from "./AjaxLoader";
import TimeoutRefresher from "./TimeoutRefresher";
import UserGroups from "./UserGroups";

export default class SetupGui {
  static run() {
    AjaxManager.initClass();
    ToastManager.initClass();

    $.fn.datepicker.dates['de'] = {
      days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
      daysShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
      daysMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      months: ["Januar", "Februar", "Märt", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
      monthsShort: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "De7"],
      today: "Heute",
      clear: "Verwerden",
      format: "mm/dd/yyyy",
      titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
      weekStart: 1
    };

    $.fn.datepicker.dates['it'] = {
      days: ["Domenica","Lunedì","Martedì","Mercoledì","Giovedì","Venerdì","Sabato"],
      daysShort: ["Dom","Lun","Mar","Mer","Gio","Ven","Sab"],
      daysMin:["Do","Lu","Ma","Me","Gi","Ve","Sa"],
      months:["Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre"],
      monthsShort:["Gen","Feb","Mar","Apr","Mag","Giu","Lug","Ago","Set","Ott","Nov","Dic"],
      today:"Oggi",monthsTitle:"Mesi",
      clear:"Cancella",
      weekStart:1,
      format:"dd/mm/yyyy"
    };

    $(function () {
      console.log("DOM loaded");

      AjaxLoader.hide();
      AjaxManager.getInstance().run();
      //
      // // bootstrap switch
      $(".default-switch").bootstrapSwitch("state");
      //
      // // bootstrap
      $("body").tooltip({
        selector: '[data-toggle="tooltip"]',
        container: "body",
        trigger: "hover",
      });
      //
      //
      $(".default-datepicker").datepicker({
        format: "dd.mm.yyyy",
        todayBtn: "linked",
        language: "de",
        autoclose: true,
        todayHighlight: true,
        calendarWeeks: false,
        orientation: "top left",
      });

      DatePickerHelper.initDatePickers();

      MultiSelectHelper.observeMultiSelects();
      MultiSelectHelper.initGroupDropdowns();
      MultiSelectHelper.initSimpleSelects();

      new PusherHelper();

      ToastManager.getInstance().run();

      toastr.options = {
        closeButton: true,
        debug: false,
        newestOnTop: false,
        progressBar: false,
        positionClass: "toast-top-right",
        preventDuplicates: false,
        onclick: null,
        showDuration: "1000",
        hideDuration: "1000",
        timeOut: "0",
        extendedTimeOut: "0",
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "fadeIn",
        hideMethod: "fadeOut",
      };

      TimeoutRefresher.initClass();
      if (
        UserGroups.hasGroup("OPERATOR_BARCODE_LOGIN_ALLOWED") ||
        UserGroups.hasGroup("CUSTOMER_BARCODE_LOGIN_ALLOWED")
      ) {
        new TimeoutRefresher();
      }
    });

    $(window).on("load", () => {
      new ThemeSwitcher();
    });

    $(document).ready(() => {
      new Navigation();
      MultiSelectHelper.initTranslations();
    });
  }
}
