require("./libs");
import SetupGui from "./classes/SetupGui";
import Translation from "./classes/Translation";

/**
 * Setup CSRF Token for ajax requests
 * https://laravel.com/docs/9.x/csrf#csrf-x-csrf-token
 */
$.ajaxSetup({
  headers: {
    "X-CSRF-TOKEN": $('meta[name="_token"]').attr("content"),
  },
});

/**
 * Load translations from database
 */
if($('body').data("user")>0) {
  i18n = window.i18n = new Translation('/resources/translations/'+document.documentElement.lang);
  await i18n.load();
}

/**
 * Initialize GUI classes
 */

SetupGui.run();
